import type { SxProps, Theme } from '@mui/material';
import { Box, Button, Chip, Tooltip, Typography, styled } from '@mui/material';
import {
  Visibility as VisibilityIcon,
  PlayCircleOutline as PlayCircleOutlineIcon,
  Download as DownloadIcon,
} from '@mui/icons-material';
import { useAssets } from '../context';
import type { ReactNode } from 'react';
import type { AssetTypeValue } from '../api';
import { Link, useLocation } from 'react-router-dom';
import { type FC } from 'react';
import { saveAs } from 'file-saver';
import { useIsFetching } from '@tanstack/react-query';

export const AssetListContainer = styled(Box)(({ theme: t }: { theme: Theme }) => {
  const { isGrid } = useAssets();

  const GridStyles = `
    display: grid;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    padding-bottom: 32px;

    ${t.breakpoints.up(1400)} {
      grid-template-columns: repeat(6, 1fr);
    };

    ${t.breakpoints.down(1400)} {
      grid-template-columns: repeat(5, 1fr);
    };

    ${t.breakpoints.down(1100)} {
      grid-template-columns: repeat(3, 1fr);
    };

    ${t.breakpoints.down(900)} {
      grid-template-columns: repeat(2, 1fr);
    };

    ${t.breakpoints.down(480)} {
      grid-template-columns: repeat(1, 1fr);
    };

    .action-hover {
      box-shadow: inset 0px 1px 0px ${t.palette.grey[700]};
    }

    .action-hover .asset-toggle-box {
      visibility: visible;
      opacity: 1;
    }
  `;

  const ListStyles = `
    display: flex;
    flex-direction: column;
    width: 100%;
  `;

  return isGrid ? GridStyles : ListStyles;
});

export const AssetItemStyled = styled(Box)(({ theme: t }: { theme: Theme }) => {
  const { isGrid, isList } = useAssets();

  return `
    display: flex;
    flex-direction: ${isGrid ? 'column' : 'row'};
    flex-grow: 1;
    width: 100%;

    ${isGrid &&
    `
      min-height: 240px;
      margin-bottom: 8px;
    `
    }

    ${isList &&
    `
      margin-bottom: 18px;
      padding-top: 12px;
      padding-bottom: 12px;
      box-shadow: inset 0px 1px 0px ${t.palette.grey[300]};
      transition: box-shadow 0.1s;

      &:hover, &.action-hover {
        box-shadow: inset 0px 1px 0px ${t.palette.grey[700]};
      }

      .asset-toggle-box {
        visibility: hidden;
      }

      &.action-hover .asset-toggle-box,
      &:hover .asset-toggle-box {
        visibility: visible;
        opacity: 1;
      }`
    }`;
});

export const AssetsPreviewContainer = styled(Box)<{ error?: boolean; src: string; }>(({
  error: isError,
  src,
  theme,
}: {
  error?: boolean;
  src: string;
  theme: Theme;
}) => {
  const { isGrid } = useAssets();

  const sizePreview = isGrid ? '100%' : 110;

  return !isError
    ? {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: sizePreview,
      minWidth: sizePreview,
      maxWidth: sizePreview,
      maxHeight: sizePreview,
      marginBottom: isGrid ? 8 : 0,
      borderRadius: '4px',
      aspectRatio: '1',
      background: `url('${src}')`,
      backgroundSize: 'cover',
      overflow: 'hidden',

      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        inset: 0,
        backdropFilter: 'blur(10px)',
      },
    }
    : {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: sizePreview,
      minWidth: sizePreview,
      maxWidth: sizePreview,
      height: '100%',
      marginBottom: isGrid ? 8 : 0,
      borderRadius: '4px',
      overflow: 'hidden',
      backgroundColor: 'white',
      border: `1px solid ${theme.palette.grey[300]}`,
    };
});

export const AssetsPreviewNameContainer = styled(Box)<{ error?: boolean }>(({ error: isError }: { error?: boolean; theme: Theme; }) => {
  const { isGrid, isList } = useAssets();

  return `
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    ${isList && `padding-left: 16px;`}
    ${isGrid && isError
      ? `
      min-height: 38px;
      max-height: 38px;
      height: 38px;
    `
      : ``
    }
  `;
});

export const AssetsPreviewContent = styled(Box)<{ id: string }>(({ id }) => ({
  display: 'flex',
  flexGrow: 1,
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  zIndex: 2,
  [`&:hover #asset_${id}`]: {
    visibility: 'visible',
    opacity: 1,
  },
}));

export const AssetPreviewImage = styled('img')({
  objectFit: 'contain',
  width: '100%',
  height: '100%',
  position: 'relative',
});

export const AssetPreviewVideo = styled('video')({
  objectFit: 'contain',
  width: '100%',
  height: '100%',
  maxHeight: '100%',
});

export const AssetToggleVisibilityContent = styled(Box)(() => {
  const { isGrid } = useAssets();

  return `
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 4px;
    ${isGrid
      ? `
      opacity: 0;
      transition: opacity 0.1s;
    `
      : ``
    }
  `;
});

export const AssetsErrorContainer = styled(Box)(({ theme }: { theme: Theme }) => {
  const { isGrid } = useAssets();

  return {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: isGrid ? '100%' : 140,
    minWidth: isGrid ? '100%' : 140,
    maxWidth: isGrid ? '100%' : 140,
    height: 140,
    marginBottom: '8px',
    borderRadius: '4px',
    overflow: 'hidden',
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.grey[300]}`,
  };
});

export const AssetName = styled(Typography)(({ theme: t }) => {
  const { isGrid, isList } = useAssets();

  return `
    word-break: break-all;
    text-align: ${isGrid ? 'center' : 'left'};
    font-size: ${t.typography.body1.fontSize};
    color: ${isGrid ? t.palette.grey[600] : t.palette.grey[900]};
    ${isList ? `margin-bottom: 12px` : ``}
  `;
});

export const AssetChip = styled(Chip)(({ theme }: { theme: Theme }) => ({
  borderRadius: '4px',
  padding: '4px 0',
  marginRight: '4px',
  marginBottom: '4px',
  height: 22,
  textTransform: 'capitalize',
  fontWeight: 500,
  cursor: 'default',

  ['.MuiChip-label']: {
    padding: '0px 4px',
    color: theme.palette.grey[800],
    fontSize: 12,
  },
}));

export const AssetChipLabel: FC<{ icon?: ReactNode; label: string; sx?: SxProps<Theme> }> = ({ icon, label, sx }) => {
  const { isGrid } = useAssets();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
      {icon}
      <Typography
        sx={{
          fontSize: isGrid ? 12 : 14,
          fontWeight: isGrid ? 500 : 400,
          maxWidth: '100px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          ...sx,
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

export const AssetPreviewButton: FC<{ onClick: () => void; assetType: AssetTypeValue | null; isError: boolean }> = ({
  onClick,
  assetType,
  isError,
}) => {
  return (
    <Button
      className="asset-toggle-box"
      sx={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
        width: '100%',
        height: '100%',
      }}
      onClick={onClick}
    >
      {!isError && assetType === 'video' && (
        <PlayCircleOutlineIcon sx={{ fontSize: 52, color: 'white', opacity: 0.85 }} />
      )}
      {!isError && assetType === 'animation' && <VisibilityIcon sx={{ fontSize: 52, color: 'white', opacity: 0.85 }} />}
      {!isError && assetType === 'json' && <VisibilityIcon sx={{ fontSize: 52, color: 'white', opacity: 0.85 }} />}
      {!isError && assetType === 'audio' && (
        <PlayCircleOutlineIcon sx={{ fontSize: 52, color: 'white', opacity: 0.85 }} />
      )}
    </Button>
  );
};

export const AssetRedirectButton: FC<{ assetId: number }> = ({ assetId }) => {
  const location = useLocation();
  return (
    <Button
      component={Link}
      to={`/assets/${assetId}`}
      target="_blank"
      state={{
        search: location.search,
      }}
      disableElevation
      variant="contained"
      sx={(t) => ({
        flexGrow: 1,
        mr: '4px',
        borderRadius: t.borderRadius.sm,
        fontSize: t.typography.body2.fontSize,
        lineHeight: t.typography.body2.lineHeight,
        opacity: 0.9,
        minHeight: 30,
      })}
    >
      Go to asset
    </Button>
  );
};

export const AssetDownloadFileButton: FC<{ isPreview?: boolean; file: string; fileName: string }> = ({
  file,
  fileName,
  isPreview,
}) => {
  const { isList, isGrid } = useAssets();

  const handleDownloadFile = () => {
    saveAs(file, `adam_${fileName}`);
  };

  return (
    <Tooltip title={isGrid ? `Download source file` : null} placement={'left'} arrow={isList}>
      <AssetUtilButton
        preview={isPreview}
        disableElevation
        variant={isPreview ?? isGrid ? 'contained' : 'text'}
        onClick={handleDownloadFile}
      >
        <DownloadIcon
          sx={{ fontSize: 22, color: isPreview ?? isGrid ? 'white' : 'black', mr: !isPreview && isList ? 1 : 0 }}
        />
        {!isPreview && isList && (
          <Typography
            sx={(t) => ({
              fontSize: t.typography.body2.fontSize,
              fontWeight: 500,
              color: t.palette.primary.main,
              whiteSpace: 'nowrap',
            })}
          >
            Download source file
          </Typography>
        )}
      </AssetUtilButton>
    </Tooltip>
  );
};

export const AssetUtilButton = styled(Button)<{ preview?: boolean }>(({
  theme: t,
  preview: isPreview,
}: {
  theme: Theme;
  preview?: boolean
}) => {
  const { isGrid } = useAssets();

  return isPreview
    ? `
    max-width: 36px;
    min-width: 36px;
    height: 36px;
    background-color: rgba(0,0,0, 0.5);
    padding: 0;
    margin-bottom: 2px;
    border-radius: 4px;
  `
    : isGrid
      ? `
          max-width: 36px;
          min-width: 36px;
          height: 36px;
          background-color: rgba(0,0,0, 0.5);
          padding: 0;
          margin-bottom: 2px;
          border-radius: 4px;
        `
      : `
          color: ${t.palette.primary.main}
          background-color: transparent;
          height: 30px;
          min-width: 36px;
          margin-bottom: 2px;
          border-radius: 4px;
        `;
});

export const AssetNoResults: FC<{ text?: string }> = ({ text }) => (
  <Typography variant="h1" sx={(t) => ({ color: t.palette.action.disabled, whiteSpace: 'nowrap' })}>
    {text ? text : 'No results'}
  </Typography>
);

export const AssetsCountResults: FC = () => {
  const { countAssets } = useAssets();
  const isFetching = useIsFetching({
    predicate: (query) => query.queryKey.at(0) === 'assets' && !query.queryKey.at(1),
  });

  return (
    <Typography
      sx={(t) => ({
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        fontSize: t.typography.body2.fontSize,
        fontWeight: 400,
        mr: 2,
        [t.breakpoints.down('md')]: {
          display: 'none',
        },
      })}
    >
      {!isFetching && countAssets ? `${countAssets} results` : ''}
    </Typography>
  );
};
