import { Skeleton, Typography } from '@mui/material';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import type { AssetEntry } from '../api';
import { useAssets } from '../context';
import getTruncatedText from 'utils/getTruncatedText';
import {
  AssetChip,
  AssetItemStyled,
  AssetName,
  AssetPreviewImage,
  AssetToggleVisibilityContent,
  AssetsPreviewContainer,
  AssetsPreviewContent,
  AssetRedirectButton,
  AssetsPreviewNameContainer,
  AssetPreviewButton,
} from './AssetsListComponents';
import { Box } from '@mui/material';
import AssetItemContextMenu from './AssetItemContextMenu';
import { AssetItemListTags } from './AssetTagsComponents';
import useInView from 'hooks/useInView';
import { AssetProjects } from './AssetProjects';
import AssetAddToFavourites from './AssetAddToFavourites';
import AssetDownloadFileButton from './AssetDownloadFileButton';
import MediaContentFailed from 'components/common/MediaContentFailed';
import { useAssetFilesSync } from '../api/getAssetFiles';
import { isEmpty } from 'lodash-es';
import useListParams from 'hooks/useListParams';

interface AssetItemProps {
  asset: AssetEntry;
}

const AssetItem: FC<AssetItemProps> = ({ asset }) => {
  const { ref, inView } = useInView();
  const { id, name, thumbnail, format, asset_type: assetType = 'fonts', tags, favourite, projects, restricted } = asset;
  const [isAssetHovered, setIsAssetHovered] = useState(false);

  const { setPreviewId } = useListParams();
  const { isGrid, isList } = useAssets();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isAssetFilesError, setIsAssetFilesError] = useState(false);

  const { isError: assetFilesError } = useAssetFilesSync({
    params: { id },
    config: { enabled: isAssetHovered && !isAssetFilesError },
  });

  useEffect(() => {
    if (assetFilesError) {
      setIsAssetFilesError(true);
    }
  }, [assetFilesError]);

  return (
    <AssetItemStyled
      onMouseLeave={() => {
        setIsAssetHovered(false);
      }}
      onMouseEnter={() => {
        setIsAssetHovered(true);
      }}
      ref={ref}
    >
      <AssetsPreviewContainer error={isError} src={isLoaded ? thumbnail : 'none'}>
        <AssetsPreviewContent id={`${id}`}>
          <AssetToggleVisibilityContent id={`asset_${id}`} className="asset-toggle-box">
            <Box sx={{ zIndex: 3, position: 'relative', display: 'flex', flexWrap: 'wrap' }}>
              {!isEmpty(format) && <AssetChip label={format} variant="filled" sx={{ textTransform: 'uppercase' }} />}
              {(isGrid && !isEmpty(projects)) && <AssetProjects projects={projects} restricted={restricted} />}
            </Box>

            {isGrid && (
              <Box
                sx={{
                  zIndex: 3,
                  position: 'absolute',
                  top: 4,
                  right: 4,
                  width: 36,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <AssetAddToFavourites assetId={id} favourite={favourite} />
                <AssetDownloadFileButton assetId={asset.id} variant="icon" tooltip parentRef={ref} />
              </Box>
            )}
            {isGrid && (
              <Box
                sx={{
                  zIndex: 3,
                  position: 'absolute',
                  bottom: 4,
                  left: 4,
                  right: 4,
                  display: 'flex',
                  flexGrow: 1,
                  height: 36,
                  maxHeight: 36,
                }}
              >
                <AssetRedirectButton assetId={asset.id} />
                <AssetItemContextMenu parentRef={ref} asset={asset} />
              </Box>
            )}

            <AssetPreviewButton
              onClick={() => {
                setPreviewId(id);
              }}
              assetType={assetType}
              isError={isError}
            />
          </AssetToggleVisibilityContent>
        </AssetsPreviewContent>

        {!inView && !isLoaded && !isError ? (
          <Skeleton
            sx={(t) => ({ transform: 'scale(1)', backgroundColor: t.palette.grey[50] })}
            animation={'wave'}
            width={'100%'}
            height={'100%'}
          />
        ) : !isError ? (
          <AssetPreviewImage
            src={thumbnail}
            onLoad={() => {
              setIsLoaded(true);
            }}
            onError={() => {
              setIsError(true);
            }}
          />
        ) : (
          <MediaContentFailed type={assetType} />
        )}
      </AssetsPreviewContainer>

      <AssetsPreviewNameContainer error={isError}>
        {isGrid && <AssetName>{getTruncatedText({ text: name })}</AssetName>}

        {isList && (
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flexGrow: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <AssetName>{getTruncatedText({ text: name })}</AssetName>
                <Box sx={{ display: 'flex', justifyContent: 'end', flexWrap: 'nowrap' }}>
                  <AssetProjects className="asset-toggle-box" projects={projects} restricted={restricted} />
                  <Typography
                    sx={(t) => ({
                      ml: 2,
                      fontSize: t.typography.body1.fontSize,
                      fontWeight: 400,
                      color: t.palette.grey[600],
                    })}
                  >
                    {id}
                  </Typography>
                </Box>
              </Box>
              <AssetItemListTags tags={tags} className="asset-toggle-box" />
            </Box>
            <Box
              className="asset-toggle-box"
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                <Box sx={{ mr: 1 }}>
                  <AssetRedirectButton assetId={id} />
                </Box>
                <Box sx={{ mr: 1 }}>
                  <AssetDownloadFileButton assetId={id} variant="icon-text" parentRef={ref} />{' '}
                </Box>
                <Box sx={{ mr: 1 }}>
                  <AssetAddToFavourites assetId={id} favourite={favourite} />{' '}
                </Box>
              </Box>
              <AssetItemContextMenu parentRef={ref} asset={asset} />
            </Box>
          </Box>
        )}
      </AssetsPreviewNameContainer>
    </AssetItemStyled>
  );
};

export default AssetItem;
