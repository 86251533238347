import { Suspense, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { isEmpty } from 'lodash-es';
import { Titled } from 'react-titled';
import { Container, Grid, Typography } from '@mui/material';
import CircularSpinner from '../../components/common/CircularSpinner';
import { Layout } from '../../components/layout';
import { FormProvider, useForm } from 'react-hook-form';
import type { AssetFormType } from 'features/assets';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  assetSchema,
  getAssetTypeFromUrl,
  useSynchronousAsset,
  AssetForm,
  CreateUpdateAssetActionBlock,
  useCreateAsset,
} from 'features/assets';
import { Box } from '@mui/system';
import { useQueryParam } from 'use-query-params';
import extractSelectedTagsIds from '../../features/assets/utils/extractSelectedTagsIds';
import MediaContentView from 'components/common/MediaContentView';

const CreateAsset = () => {
  const [baseAsset] = useQueryParam('baseAsset');
  const { data: asset, isFetching } = useSynchronousAsset({ id: Number(baseAsset), config: { enabled: !!baseAsset } });
  const formMethods = useForm<AssetFormType>({
    resolver: yupResolver(assetSchema),
    defaultValues: {
      tags: {},
    },
  });
  const { reset, handleSubmit, watch, setValue } = formMethods;
  const navigate = useNavigate();

  const { mutate, isPending } = useCreateAsset({
    config: {
      onSuccess: () => {
        navigate('/assets');
      },
    },
  });

  useEffect(() => {
    if (asset) {
      const { name, media_url, comment, customer, tags, performer, thumbnail, ai_prompt, restricted, projects } = asset;
      setValue('restricted', restricted);
      setValue('name', name);
      setValue('mediaUrl', media_url);
      setValue('thumbnail', thumbnail);
      setValue('comment', comment ?? '');
      setValue('aiPrompt', ai_prompt ?? '');
      setValue('projects', isEmpty(projects) ? [] : projects.map((p) => p.id));
      setValue('customer', customer ?? '');
      setValue('performer', performer ?? '');
      if (!isEmpty(asset.tags)) {
        const formattedTags = tags.reduce<Record<string, boolean>>((acc, currentTag) => {
          const key = `${currentTag.name}:${currentTag.id}`;
          acc[key] = true;
          return acc;
        }, {});

        setValue('tags', formattedTags);
      } else {
        setValue('tags', {});
      }
    }
  }, [asset, setValue]);

  const onSubmit = ({
    name,
    mediaUrl,
    aiPrompt,
    customer,
    tags,
    comment,
    performer,
    thumbnail,
    restricted,
    projects,
  }: AssetFormType) => {
    const selectedTagIds = extractSelectedTagsIds(tags);

    mutate({
      name,
      media_url: mediaUrl,
      ai_prompt: aiPrompt,
      customer,
      comment,
      performer,
      thumbnail,
      restricted,
      projects,
      tags: selectedTagIds,
    });
  };

  const discardChanges = () => {
    reset({
      name: '',
      mediaUrl: '',
      tags: {},
      thumbnail: '',
      comment: '',
      aiPrompt: '',
      projects: [],
      restricted: undefined,
      customer: '',
      performer: '',
    });
  };

  const [thumbnail, url] = [watch('thumbnail'), watch('mediaUrl')];

  if (isFetching) {
    return <CircularSpinner containerSx={{ height: '100vh' }} />;
  }
  return (
    <Layout childrenContainerClasses={{ padding: '24px 0 40px 0' }}>
      <Titled title={(title) => `Create asset | ${title}`} />
      <Container
        sx={(t) => ({
          display: 'flex',
          flexDirection: 'column',
          [t.breakpoints.up('md')]: {
            flexDirection: 'row',
          },
          [t.breakpoints.down('md')]: {
            px: '16px !important',
          },
        })}
        maxWidth={false}
      >
        <Suspense fallback={<CircularSpinner containerSx={{ height: '100vh' }} />}>
          <Grid container item xs={12} md={6} sx={{ pr: 3 }} display="flex" flexDirection="column" alignItems="center">
            <Box
              sx={(t) => ({
                display: 'flex',
                justifyContent: 'center',
                width: 420,
                maxHeight: 420,
                [t.breakpoints.up('xl')]: {
                  width: 700,
                  maxHeight: 700,
                },
              })}
            >
              <MediaContentView emptyState thumbnail={thumbnail} type={getAssetTypeFromUrl(url)} url={url} />
            </Box>
          </Grid>
          <Grid container item xs={12} md={6} display="flex" flexDirection="column">
            <Typography variant="h3" sx={{ mb: 2.5 }}>
              Create asset
            </Typography>
            <FormProvider {...formMethods}>
              <AssetForm />
            </FormProvider>
            <CreateUpdateAssetActionBlock
              onSubmit={handleSubmit(onSubmit)}
              onDiscard={discardChanges}
              isSubmitting={isPending}
            />
          </Grid>
        </Suspense>
      </Container>
    </Layout>
  );
};

export default CreateAsset;
