import type { ChangeEvent, FC, FocusEvent } from "react";
import { Suspense, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router";
import { useQueryClient } from "@tanstack/react-query";
import queryStringParser from 'query-string';
import { Backdrop, Button, IconButton, TextField, Typography } from "@mui/material";
import {
  FilterList as FilterListIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  ArrowBack as ArrowBackIcon,
  BookmarkBorder,
  KeyboardArrowDown,
} from '@mui/icons-material';
import { Box } from "@mui/system";
import { useBreakpoints, useModal } from "hooks";
import { debounce, isEmpty, isUndefined } from "lodash-es";
import SearchResultTags from "./SearchResultTags";
import SearchAllTags from "./SearchAllTags";
import {
  SearchQueryButton,
  SearchAllTagsSkeleton,
  SearchButton,
  SearchContainer,
  SearchResultContainer,
  SearchPositionContainer,
  SearchResultTagsSkeleton,
  SearchByStringSuggest,
  SearchButtonStyled,
  SearchPresetsListSkeleton,
} from "./SearchComponents";
import type { SearchPreset } from "features/search";
import { useSearchTagsState } from "features/search";
import SelectedTags from "./SelectedTags";
import PresetsList from "./PresetsList";
import { useSearchContext } from "../context";
import SavePreset from "./SavePreset";

const Search: FC = () => {
  const {
    searchType,
    searchTextFieldRef,
    isShowPresets,
    setShowPresets,
    isShowAllTags,
    setShowAllTags,
    isShowResultTags,
    setShowResultTags,
    tags, setTags,
    q, setQ,
    queryString, setQueryString,
    queryFieldValue, setQueryFieldValue,
    onResetValues,
  } = useSearchContext();

  const isQueryFieldValue = queryFieldValue.length >= 2;
  const refContainer = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { isBreakpoint } = useBreakpoints('sm');
  const { selectedTags, onResetStateTags, onSelectTags, onSelectTag } = useSearchTagsState();
  const { isOpen: isShowSearch, close: onClose, open: onOpen } = useModal();
  const [isShowShadow, setShowShadow] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    const onInvalidateQueries = async () => {
      // Remove queries from the cache when the user's routing is changed
      if (queryClient.getQueryCache().find({ queryKey: [searchType, 'tags', q] })) {
        await queryClient.invalidateQueries({ queryKey: [searchType, 'tags', q] });
      }
      if (queryClient.getQueryCache().find({ queryKey: [searchType, 'presets'] })) {
        await queryClient.invalidateQueries({ queryKey: [searchType, 'presets'] });
      }
    };

    onInvalidateQueries().then(r => r).catch(console.error);
  }, [queryClient, q, searchType, location.pathname]);

  const setQueryStringDebounce = useMemo(() => debounce(setQueryString, 300), [setQueryString]);

  const handleAllTags = useCallback(() => {
    setShowAllTags((prev) => !prev);
    setShowPresets(false);
  }, [setShowAllTags, setShowPresets]);

  const handleFieldFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      e.target.select();

      if (isShowSearch) return;

      onOpen();
      setShowResultTags(!isUndefined(q) && q.length >= 2);
      setQueryString(q?.length ? q : '');
    }, [isShowSearch, onOpen, q, setQueryString, setShowResultTags]);

  const handleFieldSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setQueryFieldValue(value);

      if (value.length >= 2 || !value.length) {
        setQueryStringDebounce(value);
        setShowAllTags(false);
        setShowResultTags(!!value.length);
      }
    },
    [setShowAllTags, setQueryFieldValue, setShowResultTags, setQueryStringDebounce]
  );

  const handleResetAll = useCallback(() => {
    onResetValues();
    onResetStateTags();
  }, [
    onResetValues,
    onResetStateTags,
  ]);

  const handleClose = useCallback(() => {
    onClose();
    setQueryFieldValue(() => (q?.length ? q : ''));
    onSelectTags(tags as number[]);

    setShowAllTags(false);
    setShowResultTags(false);
  }, [q, tags, setQueryFieldValue, onSelectTags, onClose, setShowAllTags, setShowResultTags]);

  const handleOnSearch = useCallback(() => {
    if (!queryFieldValue.length && !selectedTags.length) return;

    searchTextFieldRef.current?.blur();
    if (location.pathname === `/${searchType}`) {
      selectedTags.length ? setTags(selectedTags) : setTags(undefined);
      queryFieldValue.length ? setQ(queryFieldValue) : setQ(undefined);
      onClose();
    } else {
      navigate(`/${searchType}?${queryStringParser.stringify({
        tags: selectedTags.length ? selectedTags : undefined,
        q: queryFieldValue.length ? queryFieldValue : undefined,
      }, { arrayFormat: 'separator', })}`);
    }
  }, [navigate, searchType, queryFieldValue, selectedTags, location.pathname, onClose, setTags, setQ]);

  useEffect(() => {
    const conditions = [!isEmpty(queryFieldValue), !isEmpty(selectedTags), !isEmpty(tags),];
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.code === 'Enter' && isShowSearch && conditions.some(Boolean)) {
        handleOnSearch();
      }
    }
    document.addEventListener('keypress', handleKeyUp);
    return () => {
      document.removeEventListener('keypress', handleKeyUp);
    }
  }, [handleOnSearch, isShowSearch, queryFieldValue, selectedTags, tags]);

  const onScrollAllTags = useCallback(
    (value: number) => {
      setShowShadow(!!value);
    },
    [setShowShadow]
  );

  const handleOnShowPresets = useCallback(() => {
    setShowPresets(prev => !prev);
    setShowAllTags(false);
  }, [setShowPresets, setShowAllTags]);

  const handleOnSetPreset = useCallback(({ q, tags }: SearchPreset) => {
    onSelectTags(tags);
    setQueryFieldValue(q);
    setQueryStringDebounce(q);
    setShowAllTags(false);
    setShowResultTags(true);
  }, [onSelectTags, setQueryFieldValue, setQueryStringDebounce, setShowAllTags, setShowResultTags]);

  return (
    <SearchContainer ref={refContainer} show={isShowSearch}>
      <SearchPositionContainer show={isShowSearch}>
        <Box sx={{
          pl: isShowSearch ? 0.5 : 0,
          display: 'flex',
          flexWrap: 'nowrap',
          zIndex: 3,
          backgroundColor: 'white',
        }}>
          {(isBreakpoint && isShowSearch) && (
            <IconButton onClick={handleClose} sx={{ ml: -0.5, mr: 1, height: 40, width: 40, }}>
              <ArrowBackIcon sx={{ width: 20, height: 20, }} />
            </IconButton>
          )}
          <TextField
            inputRef={searchTextFieldRef}
            id={isShowSearch ? 'assets-search-field' : undefined}
            hiddenLabel
            placeholder="Search by name, tags or comment"
            size="small"
            sx={{ width: '100%' }}
            InputProps={{
              sx: (t) => ({
                width: '100%',
                minHeight: 48,
                maxHeight: 48,
                borderRadius: 24,
                backgroundColor: 'white',
                fontSize: t.typography.body1.fontSize,
              }),
            }}
            value={queryFieldValue}
            onFocus={handleFieldFocus}
            onChange={handleFieldSearch}
          />
          {
            (isShowSearch && !isBreakpoint) && <SearchButton
              color="primary"
              variant="contained"
              disableElevation
              disabled={!isQueryFieldValue && !selectedTags.length}
              onClick={handleOnSearch}>
              Search
            </SearchButton>
          }
        </Box >
        {isShowSearch && (
          <>
            <SearchResultContainer>
              {selectedTags.length ? <Suspense fallback={<SearchPresetsListSkeleton />}>
                <SelectedTags selectedTags={selectedTags} onSelectTag={onSelectTag} />
              </Suspense> : null}
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                alignItems: 'center',
                pb: 2,
                px: 0.5,
                position: 'relative',
                zIndex: 3,
                boxShadow: isShowShadow ? '0px 4px 5px -4px rgb(0,0,0, 0.4)' : 'none',
              }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', }}>
                  <SearchButtonStyled
                    onClick={handleAllTags}
                    startIcon={<FilterListIcon />}
                    endIcon={<KeyboardArrowDownIcon sx={{ transform: `rotate(${isShowAllTags ? 180 : 0}deg)`, transition: 'transform .2s' }} />}
                    variant="text"
                    active={isShowAllTags}
                    sx={{ mr: 1, whiteSpace: 'nowrap', }}>
                    All tags
                  </SearchButtonStyled>
                  <Box sx={{ display: 'flex', flexDirection: 'row', }}>
                    <SearchButtonStyled
                      id="presets-dropdown-button"
                      onClick={handleOnShowPresets}
                      variant="text"
                      active={isShowPresets}
                      startIcon={<BookmarkBorder />}
                      endIcon={<KeyboardArrowDown sx={{ transform: `rotate(${isShowPresets ? 180 : 0}deg)`, transition: 'transform .2s' }} />}
                      sx={{ mr: 1, }}>
                      Presets
                    </SearchButtonStyled>
                    <SavePreset refParent={refContainer} />
                  </Box>
                </Box>

                {(selectedTags.length || isQueryFieldValue) ? <Button
                  variant="text"
                  color="primary"
                  onClick={handleResetAll}
                  sx={{ whiteSpace: 'nowrap', }}>Reset all</Button> : null}
              </Box >

              <Suspense fallback={<SearchAllTagsSkeleton />}>
                {isShowAllTags && <SearchAllTags onScrollCallback={onScrollAllTags} />}
              </Suspense>

              <Suspense fallback={<SearchPresetsListSkeleton />}>
                {isShowPresets && <PresetsList onSetPreset={handleOnSetPreset} />}
              </Suspense>

              <Suspense fallback={<SearchResultTagsSkeleton />}>
                {isShowResultTags && <SearchResultTags />}
              </Suspense>
              {
                (queryString.length && isQueryFieldValue) ? (
                  <SearchByStringSuggest>
                    <Typography
                      component={'span'}
                      sx={(t) => ({
                        fontSize: t.typography.body1.fontSize,
                        color: t.palette.grey[600],
                        mb: 1.5,
                      })}
                    >
                      Search by names, comments and AI prompts
                    </Typography>
                    <Box>
                      <SearchQueryButton onClick={handleOnSearch} value={queryFieldValue} />
                    </Box>
                  </SearchByStringSuggest>
                ) : null
              }
              {
                isBreakpoint && (
                  <Box sx={{ position: 'relative', zIndex: 5, display: 'flex', justifyContent: 'center', height: 56, }}>
                    <SearchButton
                      color="primary"
                      variant="contained"
                      disableElevation
                      disabled={isQueryFieldValue && !selectedTags.length}
                      onClick={handleOnSearch}>
                      Search
                    </SearchButton>
                  </Box>
                )}
            </SearchResultContainer>
          </>
        )
        }
      </SearchPositionContainer >
      <Backdrop sx={{ zIndex: 8, }} open={isShowSearch} onClick={handleClose}></Backdrop>
    </SearchContainer >
  )
};

export default Search;
